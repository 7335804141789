<template>
  <section class="totalContent-edp-out">
    <div
      class="totalContent-edp-flex"
      v-show="pageDetailHide"
      v-if="refreshStatus"
    >
      <edp-header-search
        :headerLeftWord="$t('ability.headerLeftWord')"
        :searchPlaceHolder="$t('ability.searchPlaceholder')"
        @search="searchFn"
      ></edp-header-search>

      <div class="topTips fac">
        <img src="@/src/assets/member/notice.png" alt="" class="tip" />
        <span class="f14 op5"> {{ $t("ability.pageTipsWord") }}</span>
      </div>

      <div
        class="edpAbility-out edp-ability"
        v-if="pageList && pageList.length > 0"
      >
        <div class="edpAbility-out__left">
          <member-square
            :title="$t('ability.memberTitle')"
            :searchPlace="$t('ability.searchPlaceholder')"
            :dataList="pageList"
            :allowLoad="moreLoadingPd"
            :moreLoadingStatus="moreLoadingStatus"
            @search="memberSearchFn"
            @viewDetail="viewDetailFn"
            ref="memberSquareRef"
          />
        </div>

        <div class="edpAbility-out__right">
          <!--right in-->

          <div class="edp-ability__list">
            <div class="edp-ability__list__item bf-op" ref="rightDetail">
              <!-- edp-ability__list__detail -->
              <section class="edp-ability__list__detail">
                <div class="edp-ability__list__detail__img">
                  <img
                    :src="memberReportJson.photo || defaultHeadImage"
                    alt=""
                    @click="linkMember(memberReportJson)"
                  />
                </div>

                <div class="edp-ability__list__detail__info">
                  <div class="ealdi-name" @click="linkMember(memberReportJson)">
                    <span v-if="$i18n.locale === 'cn'">{{
                      $i18n.locale === "cn"
                        ? memberReportJson.cname || memberReportJson.ename
                        : memberReportJson.ename || memberReportJson.cname
                    }}</span>
                    <span v-else>{{
                      memberReportJson.ename || memberReportJson.cname
                    }}</span>
                    <em v-if="memberReportJson.empGenerations"
                      ><span>{{ memberReportJson.empGenerations }}</span></em
                    >
                  </div>

                  <!--<ul class="ealdi-tags">
                  <li
                    v-for="(itemTags, indexTags) in memberReportJson.tags"
                    :key="'ealdiTags' + indexTags"
                  >
                    {{ itemTags }}
                  </li>
                </ul>-->

                  <ul class="ealdi-details">
                    <!--<li>
                      <label>{{ $t("ability.infoTitle1") }}</label>
                      <p>{{ item.jobNum }}</p>
                    </li>

                    <li>
                      <label>{{ $t("ability.infoTitle2") }}</label>
                      <p>{{ item.phone }}</p>
                    </li>

                    <li>
                      <label>{{ $t("ability.infoTitle3") }}</label>
                      <p>{{ item.mail }}</p>
                    </li>-->

                    <li>
                      <label>{{ $t("ability.infoTitle4") }}</label>
                      <p>
                        {{
                          utils.formatLang(
                            $i18n.locale,
                            memberReportJson.dept,
                            memberReportJson.deptEname
                          )
                        }}
                      </p>
                    </li>

                    <li>
                      <label>{{ $t("ability.infoTitle5") }}</label>
                      <p>{{ memberReportJson.subdivision }}</p>
                    </li>

                    <li>
                      <label>{{ $t("ability.infoTitle6") }}</label>
                      <p>
                        {{
                          utils.formatLang(
                            $i18n.locale,
                            memberReportJson.directLeaderChineseName,
                            memberReportJson.directLeaderEnName
                          )
                        }}
                      </p>
                    </li>

                    <li>
                      <label>{{ $t("ability.infoTitle7") }}</label>
                      <p>
                        {{
                          utils.formatLang(
                            $i18n.locale,
                            memberReportJson.jobPositionCn,
                            memberReportJson.jobPosition
                          )
                        }}
                      </p>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- edp-ability__list__detail end -->

              <!-- edp-ability__list__report -->
              <section
                class="edp-ability__list__report-out"
                v-if="
                  memberReportJson.evaluationDtos &&
                  memberReportJson.evaluationDtos.length > 0
                "
              >
                <section
                  class="edp-ability__list__report"
                  v-for="(
                    itemReport, indexReport
                  ) in memberReportJson.evaluationDtos"
                  :key="'itemReport' + indexReport"
                >
                  <dl style="width: 8%">
                    <dt>{{ $t("ability.tableTitle1") }}</dt>
                    <dd>{{ indexReport + 1 }}</dd>
                  </dl>

                  <dl style="width: 35%">
                    <dt>{{ $t("ability.tableTitle2") }}</dt>
                    <dd>{{ itemReport.reportName }}</dd>
                  </dl>

                  <dl style="width: 20%">
                    <dt>{{ $t("ability.tableTitle3") }}</dt>
                    <dd>{{ itemReport.createFullName }}</dd>
                  </dl>

                  <dl style="width: 18%">
                    <dt>{{ $t("ability.tableTitle4") }}</dt>
                    <dd>
                      {{
                        itemReport.reportDate
                          ? utils.getTimeFn(itemReport.reportDate, "date", ".")
                          : "--"
                      }}
                    </dd>
                  </dl>

                  <!--<dl style="width: 15%">
                  <dt>{{ $t("ability.tableTitle5") }}</dt>
                  <dd>{{ itemReport.reportScore }}分</dd>
                </dl>-->

                  <dl class="fBasis100">
                    <dd>
                      <span
                        class="edp-ability__list__report__btn"
                        @click="
                          viewPort(memberReportJson, itemReport, indexReport)
                        "
                        >{{ $t("ability.tableButton1") }}</span
                      >
                      <!--<span class="edp-ability__list__report__btn btn-primary">{{
                      $t("ability.tableButton2")
                    }}</span>-->
                    </dd>
                  </dl>
                </section>
              </section>

              <section class="report-noResult" v-else>
                {{ $t("ability.resultNull") }}
              </section>
              <!-- edp-ability__list__report end -->
            </div>
          </div>

          <!--right in end-->
        </div>
      </div>

      <div class="user-empty-common tac" v-else>
        <img src="@/src/assets/member/user-empty.png" alt="" class="mb16" />
        <p class="f14 tac">{{ $t("departmentList.resultNull") }}</p>
      </div>

      <van-dialog
        v-if="dialogShowPage"
        :showConfirmButton="false"
        :showCancelButton="false"
        v-model="dialogShowPage"
        :close-on-click-overlay="true"
        class="edp-ability-dialog"
      >
        <section class="edp-ability-dialog__top">
          <div class="edp-ability-dialog__top__image">
            <img :src="dialogData.imageUrl" alt="" />
          </div>

          <div class="edp-ability-dialog__top__detail">
            <div>
              <div class="dadtd-content">
                <span v-if="$i18n.locale === 'cn'">{{
                  dialogData.cnName || dialogData.enName
                }}</span>
                <span v-else>{{ dialogData.enName || dialogData.cnName }}</span>

                <em v-if="dialogData.empGenerations"
                  ><span>{{ dialogData.empGenerations }}</span></em
                >
              </div>

              <p>{{ dialogData.reportName }}</p>
            </div>

            <span
              class="edp-ability-dialog__top__downloadBtn"
              @click="downloadReport"
            >
              {{ $t("ability.dialogDownloadBtn") }}
            </span>
          </div>
        </section>

        <section
          class="edp-ability-dialog__content"
          v-if="
            dialogData.reportImageList && dialogData.reportImageList.length > 0
          "
        >
          <div
            class="edp-ability-dialog__content__button eadcb-left"
            v-if="
              dialogData.reportImageList &&
              dialogData.reportImageList.length > 1
            "
            :class="{ disable: dialogImgIndex == 0 }"
            @click="dialogImgClick('left')"
          >
            <div class="eadcb__icon"></div>
            <div class="eadcb__word">{{ $t("ability.dialogPrevWord") }}</div>
          </div>

          <div class="edp-ability-dialog__content__article">
            <swiper :ref="'mySwiper'" @slideChangeTransitionEnd="onSlideChange">
              <swiper-slide
                class="swiper-slide-mainImg"
                v-for="(item, index) in dialogData.reportImageList"
                :key="'mySwiper_abReportImage' + index"
              >
                <div class="swiper-slide-mainCotent">
                  <img
                    v-for="(itemImage, indexImage) in item.imageUrl"
                    :key="'pdfImageItem' + indexImage"
                    :src="itemImage"
                    class="swiper-slide-mainImg"
                  />

                  <div class="waterSign">
                    <div
                      class="waterSign-in"
                      v-for="(itemWater, indexWater) in 200"
                      :key="'water' + indexWater"
                    >
                      {{ utils.sercetWaterSign(userInfo.cdsid) }}
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>

          <div
            class="edp-ability-dialog__content__button eadcb-right"
            v-if="
              dialogData.reportImageList &&
              dialogData.reportImageList.length > 1
            "
            :class="{
              disable: dialogImgIndex == dialogData.reportImageList.length - 1,
            }"
            @click="dialogImgClick('right')"
          >
            <div class="eadcb__icon"></div>
            <div class="eadcb__word">{{ $t("ability.dialogNextWord") }}</div>
          </div>
        </section>

        <span class="edp-ability-dialog_close" @click="closeDialog"></span>
      </van-dialog>

      <van-overlay
        :show="subLoading"
        z-index="120"
        :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
      >
        <div class="loading-wrap-common">
          <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
            >&nbsp;</van-loading
          >
        </div>
      </van-overlay>
    </div>

    <router-view v-if="!pageDetailHide" />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import memberSquare from "../components/ability/memberSquare.vue";

const pdfJS = require("pdfjs-dist");
pdfJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry");

export default {
  components: { memberSquare },
  name: "abilityList",
  data() {
    return {
      subLoading: false,
      pageList: [],

      dialogReportDetailList: [],
      dialogShowPage: false,
      dialogData: { reportImageList: [] },
      dialogImgIndex: 0,
      reportIndexId: "",

      moreLoadingPd: false,
      moreLoadingStatus: false,

      pageNum: 1,
      pageSize: 20,

      keyWords: "",
      sorted: "asc",
      memberReportJson: {},

      pageDetailHide: true,
      pageFirstVisit: true,
      refreshStatus: false,
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
      userInfo: (state) => state.login.userInfo,
    }),
  },
  watch: {
    $route(val) {
      if (val.path.indexOf("memberDetail") != -1) {
        this.pageDetailHide = false;
      } else {
        this.pageDetailHide = true;

        if (this.pageFirstVisit) {
          this.pageFirstVisit = false;
          this.refreshStatus = true;

          this.pageInit(1);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      abilityList: "ability/abilityList",
      abilityView: "ability/abilityView",
      abilityDown: "ability/abilityDown",
      memberReportDetail: "ability/memberReportDetail",
    }),
    convertPdfToImage(pdfUrl, indexOut) {
      let _this = this;

      const pdfData = pdfJS.getDocument(pdfUrl);

      pdfData.promise.then((pdf) => {
        for (let i = 1; i <= pdf._pdfInfo.numPages; i++) {
          _this.dialogData.reportImageList[indexOut].imageUrl.push("");
        }

        for (let i = 1; i <= pdf._pdfInfo.numPages; i++) {
          const canvas = document.createElement("canvas");

          pdf.getPage(i).then((page) => {
            // 获取页的尺寸
            const viewport = page.getViewport({ scale: 1 });

            // 设置canvas的尺寸
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const context = canvas.getContext("2d");

            // 将pdf页渲染到canvas上
            page
              .render({ canvasContext: context, viewport: viewport })
              .then(() => {
                _this.dialogData.reportImageList[indexOut].imageUrl[i - 1] =
                  canvas.toDataURL("image/png");

                _this.$forceUpdate();
              });
          });
        }
      });
    },
    async pageInit(num, typeMore) {
      if (num) {
        this.pageNum = num;
      }

      this.moreLoadingStatus = true;
      this.moreLoadingPd = false;

      let params = {
        current: this.pageNum,
        size: this.pageSize,
        keywords: this.keyWords,
        sortedKey: "NAME",
        sorted: this.sorted,
      };
      let res = await this.abilityList(params);

      let dataResLs =
        res.data && res.data.data && res.data.data.records
          ? res.data.data.records
          : [];

      if (!typeMore) {
        this.pageList = dataResLs;
      } else {
        this.pageList = this.pageList.concat(dataResLs);
      }

      this.moreLoadingStatus = false;

      if (this.pageNum * this.pageSize <= res.data.data.total) {
        this.moreLoadingPd = true;
      }

      if (this.pageNum == 1 && this.pageList && this.pageList.length > 0) {
        let firstAry = JSON.parse(JSON.stringify(this.pageList[0]));

        setTimeout(() => {
          this.$refs.memberSquareRef.sc0();
          this.$refs.memberSquareRef.viewDetail(firstAry, 0);
        }, 0);
      }
    },
    searchFn(data) {
      this.keyWords = data.searchName;

      this.pageInit(1);
    },
    memberSearchFn(data) {
      this.sorted = data.data.sorted;

      if (data.type === "more") {
        this.pageInit(this.pageNum + 1, "more");
      } else {
        this.pageInit(1);
      }
    },
    async viewDetailFn(data) {
      this.subLoading = true;
      this.pageList.forEach((val) => {
        if (val.cdsid === data.data.cdsid) {
          this.$set(val, "selected", true);
        } else {
          this.$set(val, "selected", false);
        }
      });

      let res = await this.memberReportDetail(data.data.cdsid);

      this.memberReportJson = res.data.data;

      this.$set(this.memberReportJson, "tags", [
        this.memberReportJson.gender,
        this.memberReportJson.age + "岁",
        this.memberReportJson.baseAddress,
        this.memberReportJson.educationBackground,
        this.memberReportJson.childless > 0 ? "有子女" : "无子女",
      ]);

      this.$refs.rightDetail.scrollTo(0, 0);

      this.subLoading = false;

      this.$forceUpdate();
    },
    viewPort(data, dataReport, indexReport) {
      this.dialogReportDetailList = data.evaluationDtos;

      this.$set(
        this.dialogData,
        "imageUrl",
        data.photo || this.defaultHeadImage
      );
      this.$set(this.dialogData, "cnName", data.cname);
      this.$set(this.dialogData, "enName", data.ename || "");
      this.$set(this.dialogData, "cdsid", data.cdsid);
      this.$set(this.dialogData, "empGenerations", data.empGenerations || "");
      this.$set(this.dialogData, "reportName", dataReport.reportName);

      this.dialogData.reportImageList = data.evaluationDtos;

      this.dialogData.reportImageList.forEach(async (val, e) => {
        let res = await this.abilityView(val.id);

        this.$set(val, "imageUrl", []);

        this.convertPdfToImage(res.data.data, e);
      });

      this.dialogImgIndex = indexReport;
      this.reportIndexId = dataReport.id;

      this.dialogShowPage = true;

      setTimeout(() => {
        this.$refs.mySwiper.swiper.slideTo(this.dialogImgIndex, 500, false);
      }, 100);
    },
    dialogImgClick(type) {
      if (type === "left") {
        if (this.dialogImgIndex > 0) {
          this.dialogImgIndex = this.$refs["mySwiper"].swiper.activeIndex - 1;
          this.$refs.mySwiper.swiper.slideTo(this.dialogImgIndex, 500, false);
        }
      } else {
        if (this.dialogImgIndex < this.dialogData.reportImageList.length - 1) {
          this.dialogImgIndex = this.$refs["mySwiper"].swiper.activeIndex + 1;
          this.$refs.mySwiper.swiper.slideTo(this.dialogImgIndex, 500, false);
        }
      }

      this.$set(
        this.dialogData,
        "reportName",
        this.dialogData.reportImageList[this.dialogImgIndex].reportName
      );
      this.reportIndexId =
        this.dialogData.reportImageList[this.dialogImgIndex].id;
    },
    closeDialog() {
      this.dialogShowPage = false;
    },
    onSlideChange() {
      this.dialogImgIndex = this.$refs["mySwiper"].swiper.activeIndex;

      this.$set(
        this.dialogData,
        "reportName",
        this.dialogData.reportImageList[this.dialogImgIndex].reportName
      );
      this.reportIndexId =
        this.dialogData.reportImageList[this.dialogImgIndex].id;
    },
    async downloadReport() {
      let res = await this.abilityDown(this.reportIndexId);

      const name = this.dialogData.reportName;
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      a.download = name + ".pdf";
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 1000);
    },
    linkMember(item) {
      let sercetCd = this.utils.encryption(item.cdsid);
      this.$router.push({
        path: "/abilityList/memberDetail",
        query: { id: sercetCd },
      });
    },
  },
  mounted() {
    if (this.$route.path.indexOf("memberDetail") != -1) {
      this.pageDetailHide = false;
    } else {
      this.pageDetailHide = true;

      if (this.pageFirstVisit) {
        this.pageFirstVisit = false;
        this.refreshStatus = true;

        this.pageInit(1);
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/src/styles/ability.scss";
</style>
